























import { Vue, Component, Prop, Ref, VModel } from 'vue-property-decorator'
import { BButton, BCard } from 'bootstrap-vue'

@Component({
  components: { BButton, BCard },
})
export default class ImageUploader extends Vue {
  @Ref()
  readonly imageInput!: HTMLInputElement

  @VModel({ type: File })
  file!: File

  @Prop({ type: String, default: '.jpg, .jpeg, .png' })
  readonly accept!: string
  @Prop({ type: String })
  readonly image?: string
  @Prop({ type: Boolean, default: null })
  readonly state?: boolean | null

  preview: string | ArrayBuffer | null = null

  get displayedImage() {
    return this.preview || this.image || null
  }

  handleFileUpload() {
    let uploadedFiles = this.imageInput.files

    if (!uploadedFiles?.length) {
      return
    }

    this.file = uploadedFiles[0]
    this.setImagePreview(uploadedFiles[0])
  }

  setImagePreview(file: File) {
    if (/\.(jpe?g|png|gif|svg)$/i.test(file.name)) {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.preview = reader.result
        },
        false,
      )
      reader.readAsDataURL(file)
    }
  }
}
