




































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { webinarTagsStore } from '@/store'
import WayupSelect from '@/components/WayupSelect.vue'
import ImageUploader from '@/components/ImageUploader.vue'
import { IWebinarTagSimple } from '@/interfaces/IWebinarTag'
import { webinarStore } from '@/store'
import { VBTooltip } from 'bootstrap-vue'
import {
  notEmpty,
  ValueValidation,
  ValidationProvider,
} from '@/helpers/validationOld'
import { urlFormatter } from '@/helpers/formatters'
import { showError } from '@/helpers/notifications'

@Component({
  components: {
    WayupSelect,
    ImageUploader,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
})
export default class WebinarInfoEdit extends Vue {
  pending = false

  tgMask = {
    mask: 'https://t.me/XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
    tokens: { X: { pattern: /[0-9a-zA-Z+-_!]/ } },
  }

  title = new ValidationProvider<string>({
    getter: () => this.webinar.title,
    setter: (value: string) => {
      this.webinar.title = value
    },
    validators: [notEmpty()],
    validationGroup: this.validation,
  })
  alias = new ValidationProvider<string>({
    getter: () => this.webinar.alias,
    setter: (value: string) => {
      this.webinar.alias = value
    },
    validators: [notEmpty()],
    validationGroup: this.validation,
  })
  description = new ValidationProvider<string>({
    getter: () => this.webinar.description,
    setter: (value: string) => {
      this.webinar.description = value
    },
    validators: [notEmpty()],
    validationGroup: this.validation,
  })
  selectedTag = new ValueValidation<IWebinarTagSimple | null>({
    initialValue: null,
    validators: [notEmpty('Выберите тег')],
    validationGroup: this.validation,
  })

  teacherName = new ValidationProvider<string>({
    getter: () => this.webinar.teacher.name,
    setter: (value: string) => {
      this.webinar.teacher.name = value
    },
    validators: [notEmpty()],
    validationGroup: this.validation,
  })
  teacherDescription = new ValidationProvider<string>({
    getter: () => this.webinar.teacher.description,
    setter: (value: string) => {
      this.webinar.teacher.description = value
    },
    validators: [notEmpty()],
    validationGroup: this.validation,
  })
  teacherAchievements = new ValidationProvider<string>({
    getter: () => this.webinar.teacher.achievements.join('\n'),
    setter: (value: string) => {
      this.webinar.teacher.achievements = value.split('\n')
    },
    validators: [notEmpty()],
    validationGroup: this.validation,
  })

  webinarProgramTitle1 = new ValidationProvider<string>({
    getter: () => this.webinar.program[0].title,
    setter: (value: string) => {
      this.webinar.program[0].title = value
    },
    validators: [notEmpty()],
    validationGroup: this.validation,
  })
  webinarProgramDescription1 = new ValidationProvider<string>({
    getter: () => this.webinar.program[0].description,
    setter: (value: string) => {
      this.webinar.program[0].description = value
    },
    validators: [notEmpty()],
    validationGroup: this.validation,
  })

  webinarProgramTitle2 = new ValidationProvider<string>({
    getter: () => this.webinar.program[1].title,
    setter: (value: string) => {
      this.webinar.program[1].title = value
    },
    validators: [notEmpty()],
    validationGroup: this.validation,
  })
  webinarProgramDescription2 = new ValidationProvider<string>({
    getter: () => this.webinar.program[1].description,
    setter: (value: string) => {
      this.webinar.program[1].description = value
    },
    validators: [notEmpty()],
    validationGroup: this.validation,
  })

  webinarProgramTitle3 = new ValidationProvider<string>({
    getter: () => this.webinar.program[2].title,
    setter: (value: string) => {
      this.webinar.program[2].title = value
    },
    validators: [notEmpty()],
    validationGroup: this.validation,
  })
  webinarProgramDescription3 = new ValidationProvider<string>({
    getter: () => this.webinar.program[2].description,
    setter: (value: string) => {
      this.webinar.program[2].description = value
    },
    validators: [notEmpty()],
    validationGroup: this.validation,
  })

  telegram = new ValidationProvider<string>({
    getter: () => this.webinar.telegram,
    setter: (value: string) => {
      this.webinar.telegram = value
    },
    validators: [notEmpty()],
    validationGroup: this.validation,
  })

  @Watch('selectedTag.value')
  onTagChanged(value: IWebinarTagSimple | null) {
    this.webinar.tagId = value?.id || null
  }

  get state() {
    return webinarStore.getState
  }

  get webinar() {
    return webinarStore.webinar
  }

  get validation() {
    return webinarStore.validation
  }

  get teacherAvatar() {
    return webinarStore.teacherAvatar
  }

  get tags() {
    return webinarTagsStore.tags
  }

  get saveButtonTitle() {
    return this.state === 'new' ? 'Создать' : 'Сохранить'
  }

  get urlFormatter() {
    return urlFormatter
  }

  get live() {
    return this.webinar.live
  }
  set live(value: boolean) {
    this.webinar.live = value
  }

  async mounted() {
    await webinarTagsStore.fetchTags()
    const tag = this.tags.find(t => t.id === this.webinar.tag?.id)
    if (tag) {
      this.selectedTag.value = tag
    }
  }

  async save() {
    if (!this.validation.validate()) {
      return showError('Некорректно заполнены поля')
    }

    this.pending = true
    let result = false

    if (this.state === 'new') {
      result = await webinarStore.addWebinar()
    } else {
      result = await webinarStore.saveWebinar()
    }

    if (result) {
      this.$router.push('/webinars')
    }

    this.pending = false
  }
}
